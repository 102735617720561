import Quill from "quill";
import { getDefaultStyles } from "../getDefaultStylesByField";
const Delta = Quill.import("delta");
const Clipboard = Quill.import("modules/clipboard");

export default class CustomClipboard extends Clipboard {
  constructor(quill, options) {
    super(quill, options);
  }
  onCapturePaste(e) {
    if (e.defaultPrevented || !this.quill.isEnabled()) {
      return;
    }
    this.raiseCallback("onPaste", e);
    if (e.clipboardData) {
      e.preventDefault();
    } else {
      return;
    }
    const range = this.quill.getSelection(true);
    if (range == null) {
      return;
    }
    const text = e.clipboardData.getData("text/plain");
    this.onPaste(range, { text });
  }
  onPaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("text");
    const range = this.quill.getSelection();
    let formats = {};
    const currentFormat = this.quill.getFormat(range.index);
    const { macro } = this.quill;
    if (Object.keys(currentFormat).length) {
      formats = currentFormat;
    } else {
      formats = getDefaultStyles(macro.name, true);
    }
    const paragraphs = text.split(/[\r\n]{2,}/);
    const lineBreaks = text.match(/[\r\n]{2,}/g);
    let paragraphMap = [];
    for (let i = 0; i < paragraphs.length; i++) {
      if (paragraphs?.length > i) {
        paragraphMap.push({ insert: paragraphs[i], attributes: formats });
      }
      if (lineBreaks?.length > i) {
        paragraphMap.push({ insert: lineBreaks[i] });
      }
    }
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .concat({ ops: paragraphMap });
    this.quill.updateContents(delta, Quill.sources.USER);
    // range.length contributes to delta.length()
    const index = delta.length() ? delta.length() - range.length : 0;
    this.quill.setSelection(index, Quill.sources.SILENT);
    this.quill.scrollIntoView();
  }
}
