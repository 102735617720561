import { AuditLogApi, MacrosApi, ProceduresApi } from "@/services";
import Quill from "quill";
import CustomStore from "devextreme/data/custom_store";
import { uniqBy, cloneDeep, unset, isEmpty } from "lodash";
import eventBus, {
  MACRO_START,
  OPEN_GENERAL_MACRO_POPUP,
  OPEN_MACRO_POPUP,
  USED_RESULTS_MACRO
} from "@/modules/eventBus";
import {
  dateRangeFilter,
  toLetters,
  getTextFromHtml,
  removeExtraDivs,
  addTextToMacro,
  createLogItem,
  sanitizeHTML
} from "@/modules/helpers";
import { from } from "rxjs";
import Store from "@/store";
import { mergeMap, scan } from "rxjs/operators";
import { AuditLogItems, MacroTypeEnum, SpecimenNumbersEnum } from "../enums";
import localforage from "localforage";

function flatDeep(arr, d = 1) {
  return d > 0
    ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
    : arr.slice();
}

const Delta = Quill.import("delta");
const REGULAR_EXPRESSIONS = {
  group: /\.\.([rpgdmsc|\s])$/i,
  single: /([.|\\])([\w\-+]+(;[dmsc])?)/i,
  decimalNumber: /[0-9]+[.]([0-9]+)/i,
  specimen: /((?:([.|\\])([\w\-+]+(;[dmsc])?))+),([\w*>=<]+)/i,
  specimenWithBlock: /(?:([.|\\])([\w\-+]+(;[dmsc])?))+,([\w*>=<]+)(,[\w])/i,
  block: /((?:[.][\w+-]+)+),(=?[\w*><=]{1,3}),([\w*><=]+)\b/i,
  singleWithoutDot: /^([\w\-+]+)(([.|\\])([\w\-+]+(;[dmsc])?))+/i,
  specimenWithoutDot: /((^([\w\-+]+(;[dmsc])?))+),([\w*>=<]+)/i
};
const tabChar = "\xA0\xA0\xA0\xA0";

const macroDBName = "macroDB";
export const macroDatabase = localforage.createInstance({
  name: "IP_PRO",
  storeName: macroDBName,
  description: "Macro database"
});

export async function syncMacroDb() {
  try {
    const { currentUser } = Store.state;
    const macrosByUser = await MacrosApi.getMacrosForCurrentUser(currentUser.id);
    const allUserIds = Object.keys(macrosByUser);
    for (const user of allUserIds) {
      macroDatabase.setItem(user, macrosByUser[user]);
    }
  } catch (error) {
    console.error(error);
  }
}
/**
 * 1. Load all the macros/primary pathologist into a indexdb store
 *
 *
 */

/**
 * @interface
 * @name options
 * @type {object}
 * @property {string} name - The name of the  field
 * @property {boolean} generalOnly
 * @property {object} defaultStyles
 */
export default class Macro {
  /**
   *
   * @param {Object} quill
   * @param {options} options
   */
  constructor(quill, options) {
    this.quill = quill;
    this.store = Store;
    this.name = options.name;
    this.defaultStyles = options.styles;
    this.generalOnly = options?.generalOnly || false;
    if (options.initialized) {
      this._bindKeyHandlers();
    }
    this.indexToInsert = 0;
  }
  start = null;

  log(...args) {
    if (Store.state.sessionDetails.isRecordingLogRocket) {
      console.log("[DEBUG]", ...args);
    }
  }

  start;

  _bindKeyHandlers() {
    this.quill.keyboard.addBinding(
      {
        key: 32
      },
      this._useMacroPhrase.bind(this)
    );
  }
  get currentSpecimen() {
    return this.store.state.accessionStore.currentSpecimen;
  }
  get currentUser() {
    return this.store.state.currentUser;
  }
  get specimens() {
    return this.store.state.accessionStore.specimens;
  }
  get currentLab() {
    return this.store.state.currentLab;
  }
  get MacroSearchWithPeriod() {
    return this.store.state.labSettings.MacroSearchWithPeriod;
  }
  get permissions() {
    return this.store.getters.permissions;
  }
  get primaryPathologist() {
    return this.store.getters["accessionStore/primaryPathologist"];
  }
  get procedureStore() {
    return ProceduresApi.getLabProceduresStore(this.currentLab);
  }
  get userMacroList() {
    let userId = this.currentUser.id;
    if (this.primaryPathologist?.guid && this.permissions.CaseFieldEditDiagnosis) {
      userId = this.primaryPathologist.guid;
    }
    return new CustomStore({
      load() {
        return macroDatabase.getItem(userId);
      },
      loadMode: "raw",
      key: "id"
    });
  }
  /**
   * @interface
   * @name range
   * @type {object}
   * @property {number} index - The index of the  field
   * @property {number} length - The length of the  field
   * @interface
   * @name context
   * @type {object}
   * @property {string} prefix - The prefix of the  field
   *
   *
   * @param {range} range
   * @param {*} context
   * @returns
   */
  _useMacroPhrase(range, context) {
    this.log("_useMacroPhrase:", range, context);
    let query = "";
    for (let i = context.prefix.length - 1; i >= 0; i--) {
      const item = context.prefix[i].trim();
      // Break if last character in prefix is period
      if (item === "." && !query.length) {
        break;
      }
      if (item) {
        query = item + query;
      } else {
        break;
      }
    }
    if (query) {
      this.start = Date.now();
      eventBus.$emit(MACRO_START);
      this._decodeMacroPhrase(range, query).then(({ status, prefix }) => {
        if (prefix && status) {
          let phraseLength = prefix.length;
          const targetIndex = Math.max(0, range.index - 1 - phraseLength);
          this.deleteTextAtIndex(targetIndex, phraseLength);
        }
      });
    }
    return true;
  }
  async _decodeMacroPhrase(range, query) {
    this.start = Date.now();
    eventBus.$emit(MACRO_START);
    const isSingleWithoutDot = REGULAR_EXPRESSIONS.singleWithoutDot.test(query);
    const isSpecimenWithoutDot = REGULAR_EXPRESSIONS.specimenWithoutDot.test(query);
    const decimalNumbers = REGULAR_EXPRESSIONS.decimalNumber.test(query);
    if ((isSingleWithoutDot || isSpecimenWithoutDot) && !this.MacroSearchWithPeriod) {
      if (decimalNumbers) {
        return { status: false };
      }
      query = "." + query;
      range.index++;
    }

    // Check if double period to open general macro popup.
    if (query === "..") {
      query = ".. ";
      range.index++;
    }
    // Check if query is empty, a single period, or a string of periods. If so, function ends.
    if (!query || query === "." || /^\.{3,}$/.test(query)) {
      return { status: false };
    }

    // // Check if query does not start with a period
    // //Check for all macros.
    // const possibleMacros = query.split(/([.|\\])/);
    // const anyDigit = possibleMacros.reduce((acc, item) => {
    //   const macro = item.trim();
    //   if (/^\d+.?\d?$/.test(macro)) {
    //     return /^\d+.?\d?$/.test(macro);
    //   }
    //   return acc;
    // }, false);
    // if (anyDigit) {
    //   return { status: false };
    // }
    this.log(query);
    query = query.toLowerCase();
    const isGroup = REGULAR_EXPRESSIONS.group.test(query);
    const isBlock = REGULAR_EXPRESSIONS.block.test(query);
    if (isGroup) {
      this.useMacroGroup(range, query);
      return { status: true };
    }

    if (isBlock) {
      const globalRegularExpression = new RegExp(REGULAR_EXPRESSIONS.block, "ig");
      const orderMacros = query.match(globalRegularExpression);
      for (const phrase of orderMacros) {
        const usedCodes = await this.useBlockMacro(phrase);
        if (usedCodes.length) {
          const usedCodesPhrase = usedCodes.join("");
          if (usedCodesPhrase.length !== query.length) {
            const code = usedCodesPhrase.toLowerCase();
            const indexOfCode = query.length - query.indexOf(code) + 1;
            range.index = range.index - indexOfCode;
            this.deleteTextAtIndex(range.index, code.length);
            const usedCodesRegExp = new RegExp(RegExp.escape(code), "ig");
            query = query.replace(usedCodesRegExp, "");
            range = this.quill.getSelection();
          } else {
            const periodAdjustment = this.MacroSearchWithPeriod ? 1 : 0;
            this.deleteTextAtIndex(
              range.index - query.length - periodAdjustment,
              query.length + periodAdjustment
            );
            const usedCodesRegExp = new RegExp(RegExp.escape(usedCodesPhrase), "ig");
            query = query.replace(usedCodesRegExp, "");
          }
        }
      }
      if (!query?.length) {
        return { status: true };
      }
    }

    const isSpecimen = REGULAR_EXPRESSIONS.specimen.test(query);
    const isSingle = REGULAR_EXPRESSIONS.single.test(query);

    if (isSpecimen) {
      this.useSpecimenMacro(range, query);
      return { status: true };
    }

    if (isSingle || this.MacroSearchWithPeriod === 0) {
      if (decimalNumbers) {
        return { status: false };
      }
      this.useSingleMacro(range, query);
      return { status: true };
    }
    return { status: false, prefix: query };
  }

  deleteTextAtIndex(index, length) {
    this.lastFormat = this.quill.getFormat(index);
    const contents = this.quill.getContents(index);
    const fullContents = this.quill.getContents(0);
    /* IP-218 Inserting a macro after a 
    new line moves the macro back up. */
    let decrementInsert = 0;
    if (Array.isArray(contents?.ops)) {
      const firstContent = contents.ops[0];
      const fullTextBoxSelected = contents.ops[0].insert === fullContents.ops[0].insert;
      if (
        typeof firstContent?.insert === "string" &&
        firstContent.insert.includes("\n") &&
        !fullTextBoxSelected
      ) {
        index += 1;
        decrementInsert++;
      }
    }
    this.quill.deleteText(index, length);
    this.indexToInsert = index - decrementInsert;
  }
  async useSingleMacro(range, prefix) {
    const macroSpecimenMatches = this.createFilterFromPhrase(prefix);
    const userMacroList = await this.userMacroList.load({
      filter: macroSpecimenMatches
    });
    if (userMacroList?.length) {
      const macros = await this.getMacrosFromUserList(userMacroList);
      if (macros?.length) {
        const panelMacros = macros.filter(macro => macro.macroType === 2);
        panelMacros.forEach(panel =>
          this.getPanelProcedures(panel).then(procedures =>
            this.applyPanelProcedures(procedures, this.currentSpecimen.id, panel, prefix)
          )
        );
        const resultsMacros = macros.filter(macro => macro.macroType !== 2);
        let phraseLength = prefix.length + 1;
        const targetIndex = Math.max(0, range.index - phraseLength);
        this.deleteTextAtIndex(targetIndex, phraseLength);
        this.consumeMacros(prefix, resultsMacros);
      }
    }
  }

  async useMacroGroup(range, query) {
    const groupMacroRegex = /\.\.([a-z|\s])/i;
    const match = query.match(groupMacroRegex);
    if (match) {
      const [fullMatch, group] = match;
      const getTypeId = group => {
        if (group === " ") {
          return MacroTypeEnum.General;
        }
        if (group.toLowerCase() === "p") {
          return MacroTypeEnum.Panel;
        }
        if (group.toLowerCase() === "g") {
          return MacroTypeEnum.Gross;
        }
        const types = Object.keys(MacroTypeEnum);
        for (const key of types) {
          const firstLetter = key.charAt(0).toLowerCase();
          if (firstLetter === group.toLowerCase()) {
            return MacroTypeEnum[key];
          }
        }
      };
      const type = getTypeId(group);
      const el = document.activeElement;
      if (el?.blur) {
        el.blur();
      }
      eventBus.$emit(this.generalOnly ? OPEN_GENERAL_MACRO_POPUP : OPEN_MACRO_POPUP, {
        type,
        callback: macros => {
          const delta = range.index - fullMatch.length - (group === " " ? 1 : 0);
          this.deleteTextAtIndex(delta, fullMatch.length);
          query = "." + macros.map(e => e.macroName).join(".");
          this.quill.setSelection(delta);
          if (macros?.length) {
            this.consumeMacros(query, macros, true, group === " ");
          }
        }
      });
    }
  }
  async useSpecimenMacro(range, query) {
    const globalSpecimenMatcher = /((?:([.|\\])([\w\-+]+(;[dmsc])?))+)(,([\w*>=<]+))?/gi;
    const input = query.match(globalSpecimenMatcher);
    if (input) {
      const macroSpecimenMatches = input.reduce(
        (acc, match) => {
          const [phrase, target] = match.split(",");
          const macroNames = this.createFilterFromPhrase(phrase); // ["macroName", {macroName}]
          if (acc.macroQuery?.length) {
            acc.macroQuery = [...acc.macroQuery, "or", ...macroNames];
          } else {
            acc.macroQuery = [...acc.macroQuery, ...macroNames];
          }
          if (target) {
            const targetSpecimens = this.getSpecimenTarget(target);
            acc.specimenMatchers = [...acc.specimenMatchers, [phrase, targetSpecimens]];
          } else {
            acc.specimenMatchers = [...acc.specimenMatchers, [phrase, [this.currentSpecimen]]];
          }
          return acc;
        },
        { macroQuery: [], specimenMatchers: [] }
      );
      this.store.commit("accessionStore/setLoading", true);
      try {
        if (macroSpecimenMatches.macroQuery.length) {
          const userMacroList = await this.userMacroList.load({
            filter: macroSpecimenMatches.macroQuery
          });
          if (userMacroList?.length) {
            const macros = await this.getMacrosFromUserList(userMacroList);
            if (macros?.length) {
              const panelMacros = macros.filter(macro => macro.macroType === 2);

              const otherMacros = macros.filter(macro => macro.macroType !== 2);
              let phraseLength = query.length + 1;
              const targetIndex = Math.max(0, range.index - phraseLength);
              this.deleteTextAtIndex(targetIndex, phraseLength);
              for (const specimenMatch of macroSpecimenMatches.specimenMatchers) {
                let [prefix, targetSpecimens] = specimenMatch;
                const specimens = [];
                for (const specimen of targetSpecimens) {
                  //If one of our target specimens is the current, we will handle consuming the macro differently.
                  if (specimen.id === this.currentSpecimen.id) {
                    await this.consumeMacros(prefix, otherMacros);
                    panelMacros.forEach(panel => {
                      const macroNameRegExp = new RegExp(
                        `(([.|\\\\]))${RegExp.escape(panel.macroName)}`,
                        "i"
                      );
                      if (macroNameRegExp.test(prefix)) {
                        this.getPanelProcedures(panel).then(
                          procedures =>
                            this.applyPanelProcedures(procedures, specimen.id, query).then(() =>
                              window.notify("Added orders from panel(s).")
                            ),
                          panel
                        );
                      }
                    });
                  } else {
                    const target = this.specimens.find(e => e.id === specimen.id);
                    specimens.push(target);
                  }
                }
                if (specimens?.length) {
                  panelMacros.forEach(panel => {
                    const macroNameRegExp = new RegExp(
                      `(([.|\\\\]))${RegExp.escape(panel.macroName)}`,
                      "i"
                    );
                    if (macroNameRegExp.test(prefix)) {
                      this.getPanelProcedures(panel).then(procedures =>
                        specimens.forEach(e => {
                          this.applyPanelProcedures(procedures, e.id, panel, query);
                        })
                      );
                    }
                  });
                  await this.store.dispatch("accessionStore/mergeSpecimensWithMacros", {
                    specimens,
                    event: { macros: otherMacros, prefix, fieldName: this.name }
                  });
                }
              }
              return true;
            } else {
              return false;
            }
          }
        }
      } catch (error) {
        window.notify("Error with triggering specimen target macro.", "error");
      } finally {
        this.store.commit("accessionStore/setLoading", false);
      }
      return false;
    }
  }
  async getPanelProcedures(panel) {
    const panelProcedures = await Promise.all(
      panel.procedures.map(procedureId =>
        this.procedureStore.load({ filter: [["id", procedureId], "and", dateRangeFilter()] })
      )
    );
    return panelProcedures.flat();
  }
  applyPanelProcedures(procedures, specimenId, panel, text) {
    // This was added to fix an issue that was somehow causing all panels to trigger at once when text was ";"
    if (text && !text?.toLowerCase().includes(panel.macroName.toLowerCase())) {
      const logItem = createLogItem(this.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Attempted to add procedures from panel "${panel.macroName}" using text "${text}"`;
      AuditLogApi.insertLogMessage(logItem);
      return;
    }
    const items = procedures.map(procedure => ({
      ...procedure,
      specimenId,
      blockNum: procedure.defaultBlockNum,
      procedureId: procedure.id
    }));
    return ProceduresApi.applyProcedures({ note: "", items }).then(() => {
      const logItem = createLogItem(this.caseDetails, AuditLogItems.ChangeAccession);
      logItem.comments = `Added procedures from panel "${panel.macroName}" using text "${text}"`;
      AuditLogApi.insertLogMessage(logItem);
      this.store.dispatch("accessionStore/getCaseQuickLinks", this.currentSpecimen.caseId);
      this.store.commit("accessionStore/setShouldPrintOrders", true);
    });
  }
  async useBlockMacro(phrase) {
    const [procedureCodes, specimenId, blockNumber] = phrase.split(",");
    const targetSpecimens = this.getSpecimenTarget(specimenId);
    const targetBlocks = this.getBlockTarget(targetSpecimens, blockNumber, targetSpecimens);
    const usedCodes = [];
    try {
      const codesFilter = procedureCodes.split(".").reduce((acc, code) => {
        if (!acc.length) {
          return [["code", code]];
        }
        return [...acc, "or", ["code", code]];
      }, []);
      const panelsFilter = procedureCodes.split(".").reduce((acc, code) => {
        if (!acc.length) {
          return [["displayName", code]];
        }
        return [...acc, "or", ["displayName", code]];
      }, []);
      let targetProcedures = await this.procedureStore.load({
        filter: [dateRangeFilter(), "and", codesFilter]
      });

      const targetPanels = await this.userMacroList
        .load({
          filter: panelsFilter
        })
        .then(macroList => {
          if (macroList?.length) {
            return this.getMacrosFromUserList(macroList);
          } else {
            return [];
          }
        });
      targetProcedures.forEach(procedure => {
        if (phrase.toLowerCase().includes(`.${procedure.code.toLowerCase()}`)) {
          usedCodes.push(`.${procedure.code}`);
        } else if (phrase.toLowerCase().includes(`/${procedure.code.toLowerCase()}`)) {
          usedCodes.push(`/${procedure.code}`);
        }
      });
      if (targetPanels?.length) {
        for (let idx = 0; idx < targetPanels.length; idx++) {
          const panel = targetPanels[idx];
          if (phrase.toLowerCase().includes(`.${panel.macroName.toLowerCase()}`)) {
            usedCodes.push(`.${panel.macroName}`);
          } else if (phrase.toLowerCase().includes(`/${panel.macroName.toLowerCase()}`)) {
            usedCodes.push(`/${panel.macroName}`);
          }
          const panelProcedures = await this.getPanelProcedures(panel);
          targetProcedures = uniqBy([...targetProcedures, ...panelProcedures.flat()], "id");
        }
      }
      const caseProcedures = await ProceduresApi.getCaseProcedures(this.currentSpecimen.caseId);
      const items = targetBlocks
        .map(cassette => {
          return targetProcedures.reduce((acc, procedure) => {
            const isExisting = caseProcedures.find(
              caseProcedure =>
                caseProcedure.description === procedure.description &&
                caseProcedure.cassetteId === cassette.id
            );
            if (!isExisting) {
              acc.push({
                ...procedure,
                cassetteId: cassette.id,
                procedureId: procedure.id,
                specimenId: cassette.specimenId,
                blockNum: cassette.blockNum
              });
            } else {
              window.notify(
                "Error adding order: Order already exists for selected block.",
                "error"
              );
            }
            return acc;
          }, []);
        })
        .flat();
      if (items.length) {
        for (let item of items) {
          if (/^NB-/.test(item.cassetteId)) {
            delete item.cassetteId;
            item = { ...item, noBlocks: true };
          }
        }
        ProceduresApi.applyProcedures({ notes: "", items })
          .then(() => {
            this.store.dispatch("accessionStore/getCaseOrders", this.currentSpecimen.caseId);
            window.notify(`Added ${items.length} order(s).`);
            this.store.commit("accessionStore/setShouldPrintOrders", true);
          })
          .catch(error => {
            if (/duplicate/i.test(error?.response?.data)) {
              window.notify(
                "Error adding order: Order already exists for selected block.",
                "error"
              );
            } else {
              window.notify("Error adding order.", "error");
            }
          });
      }
      return [...usedCodes, `,${specimenId},${blockNumber}`];
    } catch (error) {
      window.notify("Errors running block level macros", "error");
    }
    return false;
  }

  consumeMacros(prefix, macros, fromPopup, isGeneralFromPopup) {
    const consumedMacros = this.getMacrosFromPhrase({ macros, prefix });
    consumedMacros.forEach(macro => {
      sanitizeHTML(macro, Store.state.labSettings);
    });
    const range = this.quill.getSelection();
    if (this.indexToInsert > 0 && !fromPopup) {
      this.indexToInsert++;
    }
    let newDelta = new Delta().retain(this.indexToInsert);
    let outputCursorIndex = range.index;
    consumedMacros.forEach((macro, idx) => {
      let textDelta = this.getDeltaFromMacro(macro);
      const timeUsed = new Date().getTime();
      textDelta = this.insertDateTime(textDelta, timeUsed);
      if (idx > 0 && outputCursorIndex > 0) {
        newDelta.insert(" ");
        outputCursorIndex++;
      }
      if (textDelta) {
        //If the insert will be placed at the beginning of the document we will remove any linebreaks.
        if (outputCursorIndex === 0) {
          const firstOp = textDelta.ops[0];
          if (firstOp?.insert === "\n" || typeof firstOp?.insert?.multilineBreak === "string") {
            firstOp.insert = "";
          }
        }
        // If there are line breaks, they are added as a separate op to preserve formatting.
        textDelta.ops.forEach((operation, index) => {
          if (/^\n+/i.test(operation?.insert)) {
            const {
              groups: { lineBreaks, text }
            } = /^(?<lineBreaks>\n+)(?<text>.*)/i.exec(operation.insert);
            operation.insert = text;
            textDelta.ops.splice(index, 0, { insert: lineBreaks });
          }
        });
        textDelta.ops.forEach((operation, index) => {
          if (typeof operation?.insert === "string") {
            if (newDelta.ops.length) {
              const lastInsertOp = this.getLastInsertOperation(newDelta.ops);
              if (lastInsertOp?.insert) {
                if (operation.insert === "\n ") {
                  operation.insert = "\n";
                }
              }
            }
            if (index === textDelta.ops.length - 1 && macro.macroType !== MacroTypeEnum.General) {
              operation.insert += " ";
            }
            outputCursorIndex += operation.insert.length;
          }
          if (typeof operation?.insert === "object") {
            outputCursorIndex++;
          }
        });
        newDelta = newDelta.concat(textDelta);
      }
      if (macro.macroType === MacroTypeEnum.Results) {
        eventBus.$emit(USED_RESULTS_MACRO, { ...macro, timeUsed: timeUsed });
      }
    });
    if (isGeneralFromPopup) {
      for (const op of newDelta.ops) {
        if (op?.insert && range.index > 0) {
          op.insert = " " + op.insert;
        }
      }
    }
    this.quill.updateContents(newDelta);
    this.quill.setSelection(outputCursorIndex);
    this.log(`Consume macro took ${Date.now() - this.start}ms`);
    return this.store
      .dispatch("accessionStore/useMacroOnCurrentSpecimen", {
        macros: consumedMacros,
        name: this.name,
        prefix
      })
      .then(() => {
        if (isGeneralFromPopup) {
          const selection = this.quill.getSelection();
          selection.index++;
          this.quill.setSelection(selection.index, 0);
        }
      });
  }
  /* The Lab Setting & App Setting will add a filter to this phrase so that
    Users can limit the type of macros displayed.
*/
  createFilterFromPhrase(phrase) {
    const SegregateResultsMacros =
      Store.state.applicationSettings.segregateResultsMacros !== null
        ? Store.state.applicationSettings.segregateResultsMacros
        : Store.state.labSettings.SegregateResultsMacros;
    let phraseFilter = phrase // Remove the specimen targeting group.
      .split(/[.|\\]/i) //Split on all delimeters ["/", "."];
      .reduce((acc, curr) => {
        if (curr && curr.includes(";")) {
          curr = curr.split(";")[0];
        }
        if (acc.length && curr) {
          acc = [...acc, "or", ["displayName", curr]];
        } else if (curr) {
          acc = [...acc, ["displayName", curr]];
        }
        return acc;
      }, []);
    if (this?.generalOnly) {
      if (phraseFilter?.length) {
        phraseFilter = [phraseFilter, "and", ["macroType", MacroTypeEnum.General]];
      }
    } else if (SegregateResultsMacros) {
      if (phraseFilter?.length) {
        phraseFilter = [phraseFilter, "and", ["!", ["macroType", MacroTypeEnum.Results]]];
      } else {
        phraseFilter = ["!", ["macroType", MacroTypeEnum.Results]];
      }
    }

    return phraseFilter;
  }

  createFilterFromUserList(macroList) {
    return macroList.reduce((acc, curr) => {
      if (acc.length && curr) {
        acc = [...acc, "or", ["macroId", curr.id]];
      } else if (curr) {
        acc = [...acc, ["macroId", curr.id]];
      }
      return acc;
    }, []);
  }
  getMacrosFromUserList(macroList) {
    const ids = macroList.map(e => e.id);
    return from(ids)
      .pipe(
        mergeMap(id => MacrosApi.getMacroForTyping(id)),
        scan((acc, curr) => [...acc, curr], [])
      )
      .toPromise();
  }
  getSpecimenTarget(target) {
    if (typeof target === "string") {
      target = target.toUpperCase();
    }
    let targetSpecimens = [];
    if (target === "*") {
      targetSpecimens = this.specimens;
    } else if (target === ">=" || target === "=>") {
      const index = this.specimens.findIndex(e => e.id === this.currentSpecimen.id);
      targetSpecimens = this.specimens.slice(index);
    } else if (target === "<=" || target === "=<") {
      const index = this.specimens.findIndex(e => e.id === this.currentSpecimen.id);
      targetSpecimens = this.specimens.slice(0, index + 1);
    } else if (target === "<") {
      const index = this.specimens.findIndex(e => e?.id === this.currentSpecimen?.id);
      targetSpecimens = this.specimens.slice(0, index);
    } else if (target === ">") {
      const index = this.specimens.findIndex(e => e.id === this.currentSpecimen.id);
      targetSpecimens = this.specimens.slice(index + 1);
    } else {
      const targetSpecimen = this.specimens.find(
        ({ specimenOrder }) => target === specimenOrder.toUpperCase()
      );
      if (targetSpecimen) {
        targetSpecimens = [targetSpecimen];
      }
    }
    return targetSpecimens;
  }
  getBlockTarget(specimens, target, targetSpecimens) {
    if (typeof target === "string") {
      target = target.toUpperCase();
    }
    if (target === ("0" || 0)) {
      let noBlocksCassettes = [];
      for (const specimen of targetSpecimens) {
        noBlocksCassettes.push({
          id: "NB-" + specimen.specimenOrder,
          blockNum: 1,
          defaultBlockNum: 1,
          specimenId: specimen.id
        });
      }
      return noBlocksCassettes;
    }
    if (target === "*") {
      return specimens.map(e => e.cassettes).flat();
    } else if (target) {
      const primaryProvider = Store.getters["accessionStore/primaryProvider"];
      const contactNumbering = primaryProvider.contact.properties.specimenNumbering;
      const numbering =
        contactNumbering && contactNumbering !== SpecimenNumbersEnum.UseLabSettings
          ? contactNumbering
          : this.store.state.labSettings.SpecimenNumberingTypes;
      const targetBlocks = specimens.map(specimen => {
        return specimen.cassettes.filter(block => {
          if (numbering === SpecimenNumbersEnum.Numbers) {
            return new RegExp(RegExp.escape(target), "i").test(toLetters(block.blockNum));
          }
          return new RegExp(RegExp.escape(target), "i").test(block.blockNum);
        });
      });
      return targetBlocks.flat();
    }
    return null;
  }
  createMacroFilter(matches) {
    if (this.route.path === "/accession") {
      return [["macroType", 4], "and", dateRangeFilter("effectiveOn", "expiresOn"), "and", matches];
    }
    return [dateRangeFilter("effectiveOn", "expiresOn"), "and", matches];
  }
  getDeltaFromMacro(macro) {
    // If the field is frozenText, only insert diagnosis or general text, and do so into text editor
    if (/frozen/i.test(this.name) && macro.macroType === MacroTypeEnum.Results) {
      const textToInsert = removeExtraDivs(macro?.diagnosis || macro.generalText);
      const delta = this.quill.clipboard.convert({ html: textToInsert });
      const lastInsertOp = this.getLastInsertOperation(delta.ops);
      if (typeof lastInsertOp.insert === "string") {
        if (["\n\n", "\n"].includes(lastInsertOp.insert)) {
          lastInsertOp.insert = lastInsertOp.insert.replace(/\n/g, "");
        }
        lastInsertOp.insert = lastInsertOp.insert.trimEnd();
        if (macro.useTrailingSpace) {
          lastInsertOp.insert += " ";
        }
      }
      return delta;
    } else if (macro.macroType === MacroTypeEnum.General) {
      if (macro.generalText && getTextFromHtml(macro.generalText)) {
        macro.generalText = removeExtraDivs(macro.generalText);
        const delta = this.htmlToDelta(macro.generalText);

        if (!macro.insertWithFormatting) {
          const newOps = delta.ops.map(op => {
            if (op.insert) {
              if (!isEmpty(this.lastFormat)) {
                op.attributes = this.lastFormat;
              } else {
                op.attributes = this.defaultStyles;
              }
              if (typeof op.insert === "string" && op.insert.includes(tabChar)) {
                const splitOp = op.insert.split(tabChar);
                if (splitOp.length > 1) {
                  return splitOp.map((split, idx, arr) =>
                    idx != arr.length - 1
                      ? [{ ...op, insert: split }, { insert: tabChar }]
                      : { ...op, insert: split }
                  );
                }
              }
            }
            return op;
          });
          delta.ops = flatDeep(newOps, Infinity);
        }
        const lastInsertOp = this.getLastInsertOperation(delta.ops);
        if (typeof lastInsertOp.insert === "string") {
          if (["\n\n", "\n"].includes(lastInsertOp.insert)) {
            lastInsertOp.insert = lastInsertOp.insert.replace(/\n/g, "");
          }
          lastInsertOp.insert = lastInsertOp.insert.trimEnd();
          if (macro.useTrailingSpace) {
            lastInsertOp.insert += " ";
          }
        }
        return delta;
      }
    } else if (macro.macroType === MacroTypeEnum.Results) {
      let targetProp = this.name;
      //*** This target prop is changed to specimenNotes because macros do not have a specimenNotes property.
      if (this.name === "notes") {
        targetProp = "specimenNotes";
      }
      if (macro[targetProp] && getTextFromHtml(macro[targetProp])) {
        macro[targetProp] = removeExtraDivs(macro[targetProp]);
        const delta = this.htmlToDelta(macro[targetProp]);
        const lastInsertOp = this.getLastInsertOperation(delta.ops) ?? { insert: "" };
        if (
          typeof lastInsertOp.insert === "string" &&
          ["\n\n", "\n"].includes(lastInsertOp.insert)
        ) {
          lastInsertOp.insert = lastInsertOp.insert.replace(/\n/g, "");
        }
        lastInsertOp.insert = lastInsertOp.insert.trimEnd();
        const newOps = delta.ops.map(op => {
          if (typeof op.insert === "string" && op.insert.includes(tabChar)) {
            const splitOp = op.insert.split(tabChar);
            if (splitOp.length > 1) {
              return splitOp.map((split, idx, arr) =>
                idx != arr.length - 1
                  ? [{ ...op, insert: split }, { insert: tabChar }]
                  : { ...op, insert: split }
              );
            }
          }
          return op;
        });
        delta.ops = flatDeep(newOps, Infinity);
        return delta;
      }
    }
  }
  htmlToDelta(html) {
    const div = document.createElement("div");
    div.setAttribute("id", "htmlToDelta");
    div.innerHTML = `<div id="quillEditor" style="display:none">${html}</div>`;
    document.body.appendChild(div);
    const quill = new Quill("#quillEditor", {
      theme: "snow"
    });
    const delta = quill.getContents();
    document.getElementById("htmlToDelta").remove();
    return delta;
  }
  getMacrosFromPhrase({ prefix, macros }) {
    let copyOfPrefix = prefix + "";
    const prefixTarget = prefix
      .split(/[.|\\\\]/i)
      .filter(e => e)
      .map(e => {
        const macroName = e.toLowerCase().trim();
        let isNewLine = false;
        const indexOfMacroName = copyOfPrefix.indexOf(macroName);
        if (indexOfMacroName > 0) {
          const newLineMatcher = new RegExp(`\\\\${RegExp.escape(macroName)}`, "i");
          const fullString = copyOfPrefix.substring(indexOfMacroName - 1, macroName.length + 1);
          isNewLine = newLineMatcher.test(fullString);
          copyOfPrefix = copyOfPrefix.replace(fullString, "");
        }
        return [macroName.replace(/;[dsmc]/i, ""), isNewLine];
      });
    const macroMap = macros.reduce((acc, curr) => {
      if (acc[curr?.macroName?.toLowerCase()]) {
        return acc;
      }
      return { ...acc, [curr.macroName.toLowerCase()]: curr };
    }, {});
    const outputMacros = prefixTarget
      .map(([targetMacro, isNewLine]) => {
        let macro = cloneDeep(macroMap[targetMacro]);
        if (!macro) {
          return null;
        }
        const hasPermission = this.checkUserAgainstMacro(macro);
        if (!hasPermission) {
          return null;
        }

        const macroNameRegExp = new RegExp(
          `[.|\\\\]?${RegExp.escape(macro.macroName)}(;[dsmc])`,
          "i"
        );
        if (macroNameRegExp.test(prefix)) {
          const match = prefix.match(macroNameRegExp);
          const targetField = match[1].replace(";", "");
          macro = this.getSingleMacroHTML(targetField, macro);
          prefix = prefix.replace(macroNameRegExp, "");
        }
        if (isNewLine) {
          if (macro.macroType === 4) {
            if (macro.generalText && getTextFromHtml(macro.generalText)) {
              macro.generalText = `<div><br></div>${macro.generalText}`;
            }
          } else if (macro.macroType === 0) {
            const macroField = this.name === "notes" ? "specimenNotes" : this.name;
            if (macro[macroField] && getTextFromHtml(macro[macroField])) {
              macro[macroField] = `<div><br></div>${macro[macroField]}`;
            }
          }
        }
        return macro;
      })
      .filter(e => e);

    return outputMacros;
  }
  checkUserAgainstMacro(macro) {
    let status = true;
    for (const field in this.permissions) {
      if (macro[field] && !this.permissions[field]) {
        return false;
      }
    }
    return status;
  }
  getLastInsertOperation(ops) {
    if (!ops?.length) {
      return null;
    }
    const lastOperation = ops[ops.length - 1];
    const isLastOperationInsert = "insert" in lastOperation;

    if (isLastOperationInsert) {
      return lastOperation;
    }

    const isLastOperationDelete = "delete" in lastOperation;

    if (isLastOperationDelete && ops.length >= 2) {
      const penultOperation = ops[ops.length - 2];
      const isPenultOperationInsert = "insert" in penultOperation;
      const isSelectionReplacing = isLastOperationDelete && isPenultOperationInsert;

      if (isSelectionReplacing) {
        return penultOperation;
      }
    }
    return null;
  }
  getLastInsertOperationWithFormat(ops) {
    if (!ops?.length) {
      return null;
    }

    for (let i = ops.length - 1; i > -1; i--) {
      const lastOperation = ops[i];
      const isLastOperationInsert = "attributes" in lastOperation;

      if (isLastOperationInsert) {
        return lastOperation;
      }
    }

    return null;
  }
  getSingleMacroHTML(query, macro) {
    query = query.toLowerCase();
    const macroClone = cloneDeep(macro);
    let fieldsToClear = [];

    switch (query) {
      case "d": {
        fieldsToClear = ["microscopic", "specimenNotes", "caseNotes"];
        break;
      }
      case "s": {
        fieldsToClear = ["diagnosis", "microscopic", "caseNotes"];
        break;
      }
      case "m": {
        fieldsToClear = ["diagnosis", "specimenNotes", "caseNotes"];
        break;
      }
      case "c": {
        fieldsToClear = ["diagnosis", "microscopic", "specimenNotes"];
        break;
      }
      default:
        break;
    }
    fieldsToClear.forEach(field => {
      unset(macroClone, field);
    });
    //when user requests specific field we should also remove the following data.
    ["holdCodes", "cptCodes", "icdCodes", "diagnosisSummary"].forEach(field => {
      unset(macroClone, field);
    });
    return macroClone;
  }
  insertDateTime(textDelta, timeUsed) {
    let newDelta = textDelta;
    if (!newDelta?.ops) {
      return newDelta;
    }
    for (const operation of newDelta?.ops) {
      if (typeof operation?.insert === "string") {
        operation.insert = addTextToMacro(operation.insert, timeUsed);
      }
    }
    return newDelta;
  }
}
