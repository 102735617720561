<template>
  <accordion
    :data-testid="name + 'editor'"
    :title="displayName(accessKey, label || name)"
    :id="name + 'wysiwyg'"
    :accordionId="name + 'accordion'"
    :isExpanded="isExpanded"
    @toggle="toggleEditor"
  >
    <div
      class="editor rounded border"
      ref="editorMain"
      :style="`height: ${editorHeight}`"
      :class="{
        'is-invalid':
          (validator && validator.$model && validator.$invalid) || (validator && validator.$error),
        'is-valid': validator && validator.$model && !validator.$invalid
      }"
      v-shortkey="lowerCaseShortkey"
      @shortkey="toggleLowerCase"
    >
      <div class="ql-editor" ref="editor"></div>
      <div class="m-1 validation-container">
        <template v-if="(validator && validator.$error) || (validator && validator.$invalid)">
          <div
            class="validation-error"
            v-for="(key, index) in Object.keys(validator.$params)"
            :key="index"
          >
            <span class="error" v-if="!validator[key]">
              {{ validatorMsgMap[key] }}
            </span>
          </div>
        </template>
      </div>
    </div>
  </accordion>
</template>

<script>
import { getTextFromHtml, scrollToElement, validatorMsgMapBase } from "../../modules/helpers";
import Accordion from "./Accordion.vue";
import { mapGetters, mapState } from "vuex";
import CustomClipboard from "@/modules/DragonQuill/Clipboard";
import { isEmpty, debounce } from "lodash";
import { fontSizes, fontWhiteList } from "@/modules/enums";
import Quill from "quill";
import eventBus, {
  LOCK_EDITORS,
  REFRESH_EDITORS,
  SCAN_IN_CASE,
  UNLOCK_EDITORS,
  fromBusEvent
} from "@/modules/eventBus";
import WebSpellChecker from "@/modules/WebSpellChecker";
import { capitalize, altKey } from "../../modules/helpers";
import Macro from "@/modules/DragonQuill/Macro";
import { tap } from "rxjs/operators";
import { getDefaultStyles } from "@/modules/getDefaultStylesByField";

/**
 *
 * @param {Object} settings
 * @param {Object} settings.labSettings
 * @param {Object} settings.state
 */
function initializeQuill() {
  const icons = Quill.import("ui/icons");

  const Block = Quill.import("blots/block");
  const Inline = Quill.import("blots/inline");
  const Italics = Quill.import("formats/italic");
  const fonts = Quill.import("attributors/style/font");
  const fontSize = Quill.import("attributors/style/size");
  fontSize.whitelist = fontSizes;
  fonts.whitelist = fontWhiteList;
  Quill.register(fontSize, true);
  Quill.register(fonts, true);
  icons[
    "transform"
  ] = `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512"><path d="M304 32H16A16 16 0 0 0 0 48v96a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-32h56v304H80a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16h-40V112h56v32a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V48a16 16 0 0 0 -16-16zm256 336h-48V144h48c14.3 0 21.3-17.3 11.3-27.3l-80-80a16 16 0 0 0 -22.6 0l-80 80C379.4 126 384.4 144 400 144h48v224h-48c-14.3 0-21.3 17.3-11.3 27.3l80 80a16 16 0 0 0 22.6 0l80-80C580.6 386 575.6 368 560 368z"/></svg>`;

  Block.tagName = "DIV"; //Quill uses <p> by default
  Italics.tagName = "I";

  class TextTransform extends Inline {
    static create(value) {
      let node = super.create(value);
      node.style.textTransform = value;
      return node;
    }
    static formats(node) {
      return node.style.textTransform;
    }
  }
  TextTransform.blotName = "transform";
  TextTransform.tagName = "span";
  TextTransform.className = "ql-transformed";
  class CustomBold extends Inline {
    static create(value) {
      let node = super.create(value);

      node.style.fontWeight = "bold";
      return node;
    }
    static formats(node) {
      return node.style.fontWeight;
    }
  }
  CustomBold.tagName = "span"; // Quill uses <strong> by default
  CustomBold.blotName = "bold";

  Quill.register(
    {
      "blots/block": Block,
      "blots/inline": CustomBold,
      "formats/transform": TextTransform,
      "formats/italic": Italics,
      "modules/clipboard": CustomClipboard,
      "modules/macro": Macro
    },
    false
  );
}
initializeQuill();

export default {
  props: {
    height: Number,
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    value: { required: true },
    upperCase: {
      type: Boolean,
      default: false
    },
    validator: {
      type: Object
    },
    validatorMsgMap: {
      type: Object,
      default() {
        return validatorMsgMapBase;
      }
    },
    accessKey: String,
    mentions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    Accordion
  },
  mixins: [WebSpellChecker],
  data: () => ({
    isToolbarExpanded: false,
    targetList: [],
    proofReaderOpen: false,
    sizeValues: fontSizes,
    fontValues: fontWhiteList,
    isExpanded: false,
    quill: null,
    webSpellCheckInstance: null,
    isFocused: false,
    headerValues: [false, 1, 2, 3, 4, 5],
    isMultiline: true,
    elementAttr: {
      class: "ql_editor"
    },
    _editor: null,
    resizeObserver: null,
    willReopen: false,
    preventExpand: false,
    lastSelection: null,
    lowerCaseShortkey: altKey("z")
  }),
  mounted() {
    this.quill = new Quill(this.$refs.editor, {
      debug: "info",
      theme: "snow",
      modules: {
        toolbar: this.toolbar,
        clipboard: {
          matchVisual: false
        },
        macro: {
          initialized: true,
          name: this.name,
          styles: this.styles
        }
      }
    });
    // this.quill.debug("info");
    const toolbar = this.quill.getModule("toolbar");
    toolbar.addHandler("transform", this.toggleLowerCase);
    this.quill.on("text-change", () => {
      const currentText = this.quill.getText();
      this.$emit("input", currentText ? this.quill.root.innerHTML : "");
    });
    function checkNextTextEvent(engine, eventName) {
      return new Promise(resolve => {
        engine.once(eventName, resolve);
      });
    }

    this.quill.on("editor-change", (eventName, ...args) => {
      if (eventName === "selection-change") {
        // eslint-disable-next-line no-unused-vars
        const [newRange, oldRange, source] = args;
        if (newRange === null && oldRange !== null) {
          this.handleFocusOut();
        } else if (newRange !== null && oldRange === null) {
          this.handleFocusIn();
        }
        if (newRange && newRange?.length === 0) {
          this.checkFormatting();
        }
        if (source === "silent") {
          checkNextTextEvent(this.quill, "text-change").then((...args) => {
            const [delta] = args;
            if (delta?.ops instanceof Array) {
              const lastInsert = delta.ops[delta.ops.length - 1];
              if (lastInsert?.delete === 1) {
                this.checkFormatting();
              }
            }
          });
        }
      }
    });

    this.setValueFromProps();
    this.initializeEditor();
    this.customizeModules();
    this.$emit("initialized", this.quill);

    const editorElement = this.$refs.editorMain;
    this.resizeObserver = new ResizeObserver(this.setEditorSize);
    this.resizeObserver.observe(editorElement);
    eventBus.$on(UNLOCK_EDITORS, () => {
      this.willReopen = this.isExpanded;
      this.isExpanded = false;
      this.preventExpand = true;
    });
    eventBus.$on(LOCK_EDITORS, () => {
      this.isExpanded = this.willReopen;
      this.willReopen = false;
      this.preventExpand = false;
    });
    eventBus.$on(SCAN_IN_CASE, () => {
      if (this.isFocused) {
        this.quill.history.undo();
      }
    });
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    eventBus.$off(LOCK_EDITORS);
    eventBus.$off(UNLOCK_EDITORS);
    eventBus.$off(SCAN_IN_CASE);
  },
  watch: {
    value: {
      handler(nv, ov) {
        if (!this.isFocused) {
          const currentHtml = this.quill.root.innerHTML;
          if (nv !== currentHtml) {
            this.handleInsert(nv, ov);
          }
        }
      }
    }
  },
  methods: {
    setEditorSize: debounce(function () {
      const targetEditor = Object.assign({}, this.textEditors[this.name]);
      const { height } = this.$refs.editorMain.getBoundingClientRect();
      if (height < 150) {
        return;
      }
      if (height !== targetEditor.height) {
        targetEditor.height = height;
        const userSettings = {
          textEditors: { ...this.textEditors, [this.name]: targetEditor }
        };
        return this.$store.dispatch("applicationSettings/setUserSettings", userSettings);
      }
    }, 1000),
    customizeModules() {
      this.$emit("customize-modules", this.quill);
    },
    displayName(key = "", name) {
      if (key) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}`;
        }
      }
      return `${name || ""}`;
    },
    toggleEditor() {
      if (this.preventExpand) {
        return;
      }
      if (!this.isExpanded) {
        return this.focus();
      }
      this.isExpanded = !this.isExpanded;
    },
    expand() {
      this.isExpanded = true;
      this.checkFormatting();
    },
    collapse() {
      this.isExpanded = false;
    },
    async focus(isManual) {
      if (!this.isExpanded) {
        this.expand();
        await this.$nextTick();
      }
      if (this.quill) {
        const selection = this.quill.getSelection();
        if (selection == null) {
          const value = this.quill.getText();
          this.quill.setSelection(value?.length, 0);
        }
        this.quill.focus();
      }
      scrollToElement(this.$el);
      if (isManual) {
        this.handleFocusIn(true);
      }
    },
    handleValueChange(e) {
      this.editorOutput = e.value;
    },
    applyDefaultFormat() {
      const textValue = this.quill.getText();
      const selection = this.quill.getSelection();
      if (textValue && selection == null) {
        for (const key in this.styles) {
          this.quill.format(key, this.styles[key]);
        }
      }
      if (selection) {
        for (const key in this.styles) {
          this.quill.format(key, this.styles[key]);
        }
      }
    },
    initializeEditor() {
      this._editor = this.quill;
      const keyboard = this.quill.getModule("keyboard");
      keyboard.addBinding(
        {
          key: 74,
          altKey: true
        },
        this.jumpToTilde
      );
      const tab = keyboard.bindings[9];
      tab.unshift({
        key: 9,
        handler: function (range) {
          this.quill.insertText(range.index, "\xA0\xA0\xA0\xA0");
          return false;
        }
      });
      this.checkFormatting();

      this._editor.on("selection-change", (range, oldRange, source) => {
        if (range && source === "user") {
          this.checkFormatting();
        }
      });
    },
    jumpToTilde(range) {
      const text = this._editor.getText();
      if (!text.includes("~")) {
        return true;
      }
      const matches = text.matchAll(/[~]/g);
      for (const match of matches) {
        if (match.index > range.index) {
          return this._editor.setSelection(match.index, 1, "silent");
        }
      }
      const firstTilde = text.indexOf("~");
      if (firstTilde > -1) {
        return this._editor.setSelection(firstTilde, 1, "silent");
      }
    },
    _getCharByIndex(index) {
      return this.editor.getContents(index, 1).ops[0].insert;
    },
    handleFocusIn(isManual) {
      if (this.isFocused && !isManual) {
        return;
      }
      if (this.lastSelection) {
        this._editor.setSelection(this.lastSelection.index, this.lastSelection.length, "silent");
      } else {
        this.setCursorAtEnd();
      }
      this.checkFormatting();
      this.isFocused = true;
    },
    handleFocusOut() {
      const selection = this.component.getSelection();
      this.lastSelection = selection;
      setTimeout(() => (this.isFocused = false), 500);
    },
    handleMacroTrigger(range, context, editor, name) {
      this.checkMacroPhrase({
        range,
        context,
        editor,
        name,
        component: this.component
      });
    },
    checkFormatting() {
      if (this._editor?.getFormat && this.isExpanded) {
        const selection = this._editor.getSelection();
        if (selection) {
          const lineFormat = this._editor.getFormat();
          if (!lineFormat || isEmpty(lineFormat)) {
            this.applyDefaultFormat();
          }
        } else {
          this.applyDefaultFormat();
        }
      }
    },
    toggleLowerCase() {
      if (!this.isForcedUpperCase) {
        return;
      }
      const range = this.quill.getSelection();
      const formats = this.quill.getFormat(range);
      if (formats.transform === "lowercase") {
        this.quill.format("transform", null);
      } else {
        this.quill.format("transform", "lowercase");
      }
    },
    setValueFromProps() {
      this.quill.root.innerHTML = this.value;
    },
    setCursorAtEnd() {
      const selection = this.component.getSelection();
      //Check if the selection is null on the editor.
      if (selection === null || selection?.length === 0) {
        //Grab the inner quill instance.
        const quill = this.component;
        const text = this.component.getText(0);
        const length = quill.getLength();
        /**
         * Most times the -2 was working because the editor would have \n\n at the end.
         */
        const newLineChar = /\n/i;
        let trailingNewLines = 0;
        for (let i = length - 1; i > 0; i--) {
          const char = text[i];
          if (newLineChar.test(char)) {
            trailingNewLines++;
          } else {
            break;
          }
        }
        this.component.setSelection(length ? length - trailingNewLines : 0, 0);
      }
    },
    handleInsert(nv, ov) {
      const selection = this.component.getSelection();
      this.quill.root.innerHTML = nv;
      if (this.isFocused) {
        const diff = getTextFromHtml(nv)?.length - getTextFromHtml(ov)?.length;
        this.component.setSelection(
          selection?.index ? selection?.index + diff : getTextFromHtml(nv).length + 1,
          0
        );
      }
    }
  },
  subscriptions() {
    const refreshEditor$ = fromBusEvent(REFRESH_EDITORS).pipe(
      tap(() => {
        this.setValueFromProps();
      })
    );

    return {
      refreshEditor$
    };
  },
  computed: {
    ...mapState({
      labSettings: state => state.labSettings,
      textEditors: state => state.applicationSettings.textEditors,
      dictionaryName: state => state.applicationSettings.dictionaryName
    }),
    ...mapGetters(["webSpellcheckerLoaded"]),
    component() {
      if (this.quill) {
        return this.quill;
      }
      return {};
    },
    editorHeight() {
      if (this.height) {
        if (this.height > 150) {
          return this.height + "px";
        }
        return "150px";
      }
      const savedEditor = this.textEditors[this.name];
      if (savedEditor && savedEditor?.height) {
        return savedEditor.height + "px";
      }
      return "320px";
    },
    styles() {
      return getDefaultStyles(this.name, true);
    },
    toolbar() {
      if (this.toolbarDisabled) {
        return [];
      }
      return [
        [{ size: fontSizes }],
        [{ font: fontWhiteList }],
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }],
        ["link", "image"],
        ["transform"]
      ];
    },
    editorOutput: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      }
    },
    editorRef() {
      return this.$refs.editor.instance;
    },
    toolbarDisabled() {
      if (
        this.labSettings.EditorToolbarsDisabled &&
        this.labSettings.EditorToolbarsDisabled.split(",").includes(this.name)
      ) {
        return true;
      }
      return false;
    },
    isForcedUpperCase() {
      let settingName = "";
      switch (this.name) {
        case "notes":
          settingName = "SpecimenNote";
          break;
        case "caseNotes":
          settingName = "CaseNote";
          break;
        default:
          settingName = capitalize(this.name);
      }
      if (settingName) {
        return Boolean(this.labSettings["ForceUpperCase" + settingName]);
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.toolbar {
  width: 100%;
}
.title {
  text-transform: capitalize;
  font-size: 1.2rem;
}
.is-invalid {
  border-color: #dc3545 !important;
}
.is-valid {
  border-color: #28a745 !important;
}
.editor {
  resize: vertical;
  display: flex;
  flex-direction: column;
  min-height: 150px;
  max-height: 75vh;
  .ql-editor {
    flex: 1;
    overflow: auto;
    padding: 0;
  }
}

::v-deep .dx-icon,
::v-deep .dx-dropdowneditor-icon {
  &::before {
    color: #333 !important;
  }
}
.ql-editor {
  padding: 12px 0;
  font-size: 18px;
}
</style>
